import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router/router';
import store from '@/store';
import 'material-design-icons-iconfont';
import axios from 'axios'
import emailjs from 'emailjs-com';


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router, store, vuetify, axios, emailjs
}).$mount('#app')
