<template>
  <section
      class="vc_row py-md-0 bg-cover bg-center black"
      data-slideshow-bg="true">
    <span class="row-bg-loader"></span>
    <div class="lqd-particles-bg-wrap">
      <div class="ld-particles-container">
        <div
            class="ld-particles-inner"
            id="ld-1539870338666-b91d3ebc-7c61"
            data-particles="true"
            data-particles-options='{"particles":{"number":{"value":180},"color":{"value":"#ffffff"},"shape":{"type":["circle"]},"opacity":{"value":0.75,"random":true,"anim":{"enable":true,"opacity_min":0.2,"speed":0,"sync":true}},"size":{"value":3,"random":true,"anim":{"enable":true,"size_min":40,"speed":0}},"move":{"enable":true,"direction":"right","speed":1,"random":true,"out_mode":"out"}},"interactivity":[]}'>
        </div><!-- /.lqd-particles-inner -->
      </div><!-- /.ld-particles-container -->
    </div><!-- /.lqd-particles-bg-wrap -->
    <div class="container1">
      <div class="row1 fullheight d-flex flex-wrap align-items-center">

        <div class="lqd-column col-md-8 col-md-offset-2 text-center px-md-9 mb-5">
          <div class="ld-fancy-heading" style="display: flex; justify-content: center">
            <div style="align-items: center; display: flex;">
              <img class="pointer_none" src="../../public/assets/img/logo_white.png" style="height: 150px" >
            </div>
          </div>
          <div class="ld-fancy-heading">
          <div class="h1 text-white mb-5 atto pointer_none">
            Atto<span class="labs">Labs</span>
          </div>
          </div>
          <div class="ld-fancy-heading">
            <h2 class="h1 text-white mb-5" style="font-family: 'Source Sans Pro', sans-serif !important;" data-text-rotator="true" data-fittext="true" data-fittext-options='{ "maxFontSize": 35 }'>
									<span class="ld-fh-txt pointer_none">
										Engineers for Future
									</span>
            </h2>
          </div><!-- /.ld-fancy-heading -->
        </div><!-- /.lqd-column col-md-8 -->
        <!-- /.lqd-column col-md-12 -->
      </div><!-- /.row -->

    </div><!-- /.container -->
    <div class="a-link">
      <a class="titlebar-scroll-link" style="color: white !important;" href="#about" data-localscroll="true"><i
          class="fa fa-angle-down"></i></a>
    </div>
  </section>
</template>

<script>
export default {
  name: "main-cover"
}
</script>

<style scoped>

</style>