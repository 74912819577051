<template>
    <div id="wrap">
      <router-view/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  created() {
    this.GetOffers()
  },
  computed: mapGetters(['OFFERS']),
  watch: {
    '$route'() {
      document.scrollingElement.scrollTop = 0
      location.reload()
    }
    },
    methods: {
      GetOffers() {
        fetch('/offers.json', {})
            .then(response => {
              response.json().then((data) => {
                this.$store.commit('setOffers', data);
              });
              console.log(this.OFFERS)
            })
      },
    }
  }
</script>

<style>
@import "styles/styles.css";
</style>
