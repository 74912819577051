import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        headerState: true,
        offers: [],
        indexOfOffer: 1
    },

    mutations: {
        changeHeaderState(state, headerState){
            state.headerState = !headerState
        },
        setOffers(state, offers){
            state.offers = offers
        },
        setIndexOfOffer(state, indexOfOffer){
            state.indexOfOffer = indexOfOffer
        }
    },
    getters: {
        CHANGE_HEADER(state){
            return state.headerState
        },
        OFFERS(state){
            return state.offers
        },
        INDEX_OF_OFFER(state){
            return state.indexOfOffer
        }
    }
})

export default store