<template>
  <section class="vc_row about img p-9_new" id="about">
    <div class="liquid-row-overlay bg-fade-dark-06"></div>
    <div class="container-fluid px-0 ">
      <div class="row1 mx-0 d-md-flex flex-wrap align-items-stretch text-align">
        <header class="fancy-title mb-5 text-align">
          <h2 class="text-white">О нас</h2>
        </header>
      </div><!-- /.row mx-0 -->
      <div class="ont-size-17 text-white about_text">
        <h5 class="text-white lh-185">
          Мы являемся одной из ведущих компаний в мире ИТ и уделяем особое внимание оптимизации
          основных бизнес-процессов наших клиентов. Но добиться успеха можно только с помощью одного:
          сотрудников AttoLabs! С нами люди объединяют идеи и технологии, и поэтому мы способствуем
          личному развитию наших сотрудников. Потому что, когда они развиваются, мы становимся еще
          лучше.
        </h5>
      </div>
    </div><!-- /.container-fluid px-0 -->
  </section>
</template>

<script>
export default {
  name: "main-about"
}
</script>

<style scoped>
.p-9_new{
  padding: 6rem;
}
.about_text{
  position: relative;
  display: flex;
  height: 35vh;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 1440px) {
  .p-9_new {
    padding: 5% 16%;
  }
  .about_text{
    position: relative;
    display: flex;
    height: 48vh;
    align-items: center;
    text-align: center;
  }
  h5 {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .p-9_new {
    padding: 2rem
  }
  .about_text{
    position: relative;
    display: flex;
    height: 50vh;
    align-items: center;
    text-align: center;
  }
}

</style>