<template>
  <div>
    <main-header/>
    <main id="content" class="content">
      <main-cover/>
      <main-about/>
      <main-jobs/>
      <main-contact/>
      </main>
    <main-footer/>
  </div>
</template>

<script>

import MainHeader from "@/components/main-header";
import MainFooter from "@/components/main-footer";
import MainContact from "@/components/main-contact";
import MainCover from "@/components/main-cover";
import MainJobs from "@/components/main-jobs";
import MainAbout from "@/components/main-about";

export default {
  name: "Home",
  components: {MainAbout, MainJobs,  MainCover, MainContact, MainFooter, MainHeader},
  created() {
    this.$store.commit('changeHeaderState', this.CHANGE_HEADER);
  }
}
</script>

<style scoped>

</style>