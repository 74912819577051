import Vue from 'vue';
import VueRouter from 'vue-router';
import Jobs from '../pages/Job-Offer'
import Home from '../pages/Home'


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            props: true
        },
        {
            path: '/jobs',
            name: 'jobs',
            component: Jobs,
            props: true
        }
    ],
})


export default router;