<template>
  <div>
    <div>
      <h2 class="mt-0">{{ OFFERS.Jobs[INDEX_OF_OFFER].name }}</h2>
      <p>{{ OFFERS.Jobs[INDEX_OF_OFFER].description }}</p>
      <hr>
      <p>{{ OFFERS.Jobs[INDEX_OF_OFFER].text }}</p>
      <span>{{ OFFERS.Jobs[INDEX_OF_OFFER].name_of_markers }}</span>
      <ul style="margin-left: 20px">
        <li style="margin: 15px 0 0 55px" v-for="(marker, id) in OFFERS.Jobs[INDEX_OF_OFFER].markers" :key="id">
          {{ marker }}
        </li>
      </ul>
      <span>{{ OFFERS.Jobs[INDEX_OF_OFFER].name_of_markers_2 }}</span>
      <ul style="margin-left: 20px">
        <li style="margin: 15px 0 0 55px" v-for="(marker, id) in OFFERS.Jobs[INDEX_OF_OFFER].markers_2" :key="id">
          {{ marker }}
        </li>
      </ul>
      <span>{{ OFFERS.Jobs[INDEX_OF_OFFER].Knowledge_of_lg }}</span>
      <ul style="margin-left: 20px">
        <li style="margin: 15px 0 0 55px" v-for="(marker, id) in OFFERS.Jobs[INDEX_OF_OFFER].languages" :key="id">
          {{ marker }}
        </li>
      </ul>
      <hr>
      <p>
        {{ OFFERS.Jobs[INDEX_OF_OFFER].conditions }}
      </p>
      <p>
        {{ OFFERS.Jobs[INDEX_OF_OFFER].guarantee }}
      </p>
    </div>
    <div class="container1">
      <div class="lqd-column col-md-5r col-md-offset-1 text-center">

        <div class="lqd-column-inner border-athens-gray pt-40 pb-35 px-5">

          <h2 class="h3 mt-0 mb-1">Оставьте свои контакты</h2>
          <p class="font-size-15 mb-35">Мы свяжемся с Вами</p>

          <div class="contact-form contact-form-inputs-filled contact-form-button-block">
            <div>
              <div class="row1">
                <form ref="form" @submit.prevent="sendEmail">
                  <div class="col-md-12">
                    <input class="bg-athens-gray px-4" type="text" v-model="name" name="user_name" aria-required="true"
                           aria-invalid="false" placeholder="Ваше Имя">
                  </div><!-- /.col-md-12 -->
                  <div class="col-md-12">
                    <input class="bg-athens-gray px-4" type="email" v-model="email" name="user_email"
                           aria-required="true"
                           aria-invalid="false" placeholder="Ваш E-Mail" required>
                  </div><!-- /.col-md-12 -->
                  <div class="col-md-12">
                    <textarea class="bg-athens-gray px-4" cols="10" rows="4" v-model="message" name="message"
                              aria-required="true" aria-invalid="false" placeholder="Ваше Сообщение"
                              required></textarea>
                  </div>
                  <div class="col-md-12">
                    <input class="bg-athens-gray px-4" type="file" name="file" aria-required="true"
                           aria-invalid="false" placeholder="резюме" required>
                  </div><!-- /.col-md-12 -->
                  <div class="col-md-12">
                    <input style="display: none;" class="bg-athens-gray px-4" v-model="OFFERS.Jobs[INDEX_OF_OFFER].name"
                           name="user_job">
                  </div><!-- /.col-md-12 -->

                  <div class="col-md-12" style="padding: 0 13px">
                    <div class="accept_data font-size-12">
                      <input class="checkbox_local" type="checkbox" id="checkbox"
                             @input="check()"
                             v-model="checked">Я cогласен на обработку моих
                      персональных данных
                    </div>
                  </div>
                  <div class="lqd-column col-md-12">
                    <v-btn
                        class="width_of_button ma-2"
                        :loading="loading"
                        @click="sendEmail(); loader = 'loading'"
                        :disabled="!disabled"
                    >Отправить
                    </v-btn>
                  </div><!-- /.col-md-12 -->
                </form>
                <div class="text-center ma-2">
                  <v-snackbar
                      v-model="snackbar"
                      :timeout="timeout"
                  >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Закрыть
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
              </div><!-- /.row -->
            </div>
            <div class="contact-form-result hidden"></div><!-- /.contact-form-result -->
          </div><!-- /.contact-form -->

        </div><!-- /.lqd-column-inner -->

      </div><!-- /.lqd-column col-md-5 -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import emailjs from 'emailjs-com';

export default {
  name: "job-1",
  data() {
    return {
      sent: false,
      checked: false,
      disabled: false,
      snackbar: false,
      text: '',
      timeout: 6000,
      name: '',
      email: '',
      message: '',
      loader: null,
      loading: false,
    }
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 6000)

      this.loader = null
    },
  },
  computed: mapGetters(['OFFERS', 'INDEX_OF_OFFER']),
  methods: {
    sendEmail() {
      if (this.email === ''){
        this.text = 'Заполните пожалуйста поле "Ваш E-Mail"'
        this.snackbar = true;
        this.checked = false;
        this.disabled = false;
      }
      else{
        emailjs.sendForm('service_w4gstsr', 'template_43o9s54', this.$refs.form, 'user_WEDPSsrc2xiOw4YsptthI')
            .then((result) => {
              if (result.text === 'OK') {
                this.text = 'Форма успешно отправлено!';
                this.sent = true;
                this.snackbar = true;
                this.checked = false;
                this.disabled = false;
                this.name = '';
                this.email = '';
                this.message = '';
                this.$refs.form.file.value = '';
              }
            }, (error) => {
              this.text = 'Ошибка сервера'
              this.snackbar = true;
              console.log('FAILED...', error.text);
            });
      }

    },

    check() {
      if (this.checked === true) {
        this.disabled = !this.disabled
      } else {
        this.disabled = true
      }
    }
  }

}
</script>

<style scoped>

.inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.fileInput {
  cursor: pointer;
  height: 50px;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}

</style>