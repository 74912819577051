<template>
  <footer class="main-footer bg-cape-cod" data-sticky-footer="true">
    <section class="bt-fade-white-015 pt-35 pb-35 mt-50">
      <div class="container1">
        <div class="row1">
          <div style="text-align: center">
            <p class="my-0"><span style="font-size: 15px;">© Copyright 2021 AttoLabs.</span></p>
          </div><!-- /.col-md-6 text-md-right -->

        </div><!-- /.row -->
      </div><!-- /.container -->
    </section>

  </footer><!-- /.main-footer -->
</template>

<script>
export default {
  name: "main-footer"
}
</script>

<style scoped>

</style>