<template>
  <section id="contact" class="vc_row pt-130 pb-130">
    <div class="container1">
      <div class="row1">

        <div class="lqd-column col-md-6 text_align1">

          <h2 class="h3 mt-0 mb-1">Контакты</h2>
          <hr class="mb-40">

          <div class="row1 mb-20">
            <h2 class="h3 mt-0 mb-1 country">AttoLabs Германия</h2>

            <div class="lqd-column col-sm-6 text_align1">

              <p class="font-size-16 text-primary">Наш Адрес</p>
              <p class="font-size-15">Мари-Кюри-Штрассе 11-17, <br>53757 Санкт-Августин, <br> Федеративная Республика Германия</p>

            </div><!-- /.col-sm-6 -->

            <div class="lqd-column col-sm-6 text_align1">

              <p class="font-size-16 text-primary">Связаться с Нами</p>
              <p class="font-size-15">+49 151 535 77791 <br>
                +49 228 6888 544 <br>
                contact@attolabs.de</p>

            </div><!-- /.col-sm-6 -->

          </div><!-- /.row -->
          <div class="row1 mb-20">
            <h2 class="h3 mt-0 mb-1 country">AttoLabs Таджикистан</h2>
            <div class="lqd-column col-sm-6 text_align1">

              <p class="font-size-16 text-primary">Наш Адрес</p>
              <p class="font-size-15">ул. Фирдавси 128, <br>735700 Худжанд, <br> Республика Таджикистан</p>

            </div><!-- /.col-sm-6 -->

            <div class="lqd-column col-sm-6 text_align1">

              <p class="font-size-16 text-primary">Связаться с Нами</p>
              <p class="font-size-15">+992 92 7752111<br>+992 3422 62111<br>hr@attolabs.de</p>

            </div><!-- /.col-sm-6 -->

          </div><!-- /.row -->


          <div class="ld-gmap" style="text-align: center;">
            <img src="../../public/assets/img/LOGO_ATTO_FULL_TEXT.jpg">
          </div>
          <!-- /.ld-gmap -->

        </div><!-- /.col-md-6 -->

        <div class="lqd-column col-md-5r col-md-offset-1 text-center">

          <div class="lqd-column-inner border-athens-gray pt-40 pb-35 px-5">

            <h2 class="h3 mt-0 mb-1">Оставьте свои контакты</h2>
            <p class="font-size-15 mb-35">Мы свяжемся с Вами</p>

            <div class="contact-form contact-form-inputs-filled contact-form-button-block">
              <div>
                <div class="row1">
                  <form ref="form" @submit.prevent="sendEmail">
                    <div class="col-md-12">
                      <input class="bg-athens-gray px-4" type="text" v-model="name" name="user_name" aria-required="true"
                             aria-invalid="false" placeholder="Ваше Имя" required>
                    </div>
                    <div class="col-md-12">
                      <input class="bg-athens-gray px-4" type="email" v-model="email" name="user_email" aria-required="true"
                             aria-invalid="false" placeholder="Ваш E-Mail" required>
                    </div>
                    <div class="col-md-12">
                    <textarea class="bg-athens-gray px-4" cols="10" rows="4" v-model="message" name="message"
                              aria-required="true" aria-invalid="false" placeholder="Ваше Сообщение" required></textarea>
                    </div>

                  <div class="col-md-12" style="padding: 0 13px">
                    <div class="accept_data font-size-13"><input class="checkbox_local" type="checkbox" id="checkbox"
                        @input="check()" v-model="checked">Я cогласен на обработку моих персональных данных
                    </div>
                  </div>
                  <div class="lqd-column col-md-12">
                    <v-btn
                        class="width_of_button ma-2"
                        :loading="loading"
                        @click="sendEmail(); loader = 'loading'"
                        :disabled="!disabled"
                    >
                      Отправить</v-btn>
                  </div>
                  </form>
                  <div class="text-center ma-2">
                    <v-snackbar
                        v-model="snackbar"
                        :timeout="timeout"
                    >
                      {{ text }}

                      <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                          Закрыть
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "main-contact",
  data() {
    return {
      sent: false,
      checked: false,
      disabled: false,
      snackbar: false,
      text: '',
      timeout: 6000,
      name: '',
      email: '',
      message: '',
      loader: null,
      loading: false,
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 4000)

      this.loader = null
    },
  },
  methods: {
    sendEmail() {
      if (this.email === '' || this.name === '' || this.message === ''){
        this.text = 'Заполните пожалуйста все поля'
        this.snackbar = true;
        this.checked = false;
        this.disabled = false;
      }
      else {
        emailjs.sendForm('service_w4gstsr', 'template_ef8ludh', this.$refs.form, 'user_WEDPSsrc2xiOw4YsptthI')
            .then((result) => {
              if (result.text === 'OK') {
                this.text = 'Форма успешно отправлено!'
                this.sent = true;
                this.snackbar = true;
                this.checked = false;
                this.disabled = false;
                this.name = '';
                this.email = '';
                this.message = '';
              }
            }, (error) => {
              this.text = 'Ошибка сервера'
              this.snackbar = true;
              console.log('FAILED...', error.text);
            });
      }
    },
    check() {
      if (this.checked === true) {
        this.disabled = !this.disabled
      } else {
        this.disabled = true
      }
    }
  },
}
</script>

<style scoped>

</style>