<template>
  <div>
    <div class="titlebar titlebar-sm scheme-light text-center" data-parallax="true"
         data-parallax-options='{ "parallaxBG": true }' style="background-color: black">
    <div class="titlebar titlebar-sm scheme-light text-center">
      <header class="main-header-overlay">
        <main-header></main-header>
      </header>

      <div class="titlebar-inner">
        <div class="lqd-particles-bg-wrap">
          <div class="ld-particles-container">
            <div
                class="ld-particles-inner"
                id="ld-1539870338666-b91d3ebc-7c61"
                data-particles="true"
                data-particles-options='{"particles":{"number":{"value":180},"color":{"value":"#ffffff"},"shape":{"type":["circle"]},"opacity":{"value":0.75,"random":true,"anim":{"enable":true,"opacity_min":0.2,"speed":0,"sync":true}},"size":{"value":3,"random":true,"anim":{"enable":true,"size_min":40,"speed":0}},"move":{"enable":true,"direction":"right","speed":1,"random":true,"out_mode":"out"}},"interactivity":[]}'>
            </div>
          </div>
        </div>

        <div class="container1 titlebar-container">
          <div class="row1 titlebar-container">
            <div class="titlebar-col col-md-12 pointer_none">
              <h1 data-fittext="true" data-fittext-options='{ "maxFontSize": 80, "minFontSize": 32 }'>Наши Вакансии</h1>
            </div>
          </div>
        </div>
      </div>

    </div>
      </div>

    <main id="content" class="content">

      <section class="pt-50_2">
        <div class="container1">
          <div class="row1">
            <div class="lqd-column col-md-12">
              <div class="tabs tabs-nav-side tabs-nav-shadowed">
                <v-card
                    class="mx-auto toggle_menu"
                    tile
                >
                  <v-list>
                    <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                        mandatory
                        @change="click1()"
                    >
                      <v-list-item
                          v-for="(offer, i) in OFFERS.Jobs"
                          :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="offer.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
                <div class="tabs-content" >
                  <div id="ld-tab-pane-1" role="tabpanel" class="tabs-pane pl-md-5 fade active in">
                    <job-1/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <main-footer/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Job1 from "@/components/jobs/job-1";
import MainFooter from "@/components/main-footer";
import MainHeader from "@/components/main-header";
export default {
  data: () => ({
    selectedItem: 1
  }),
  name: "Job-Offer",
  components: {MainHeader, MainFooter, Job1},
  computed: mapGetters(['OFFERS', 'INDEX_OF_OFFER']),

  mounted() {
    this.selectedItem = this.INDEX_OF_OFFER
    this.$store.commit('changeHeaderState', !this.CHANGE_HEADER);
    console.log('rt', this.INDEX_OF_OFFER)
  },
  methods:{
    click1() {
      this.$store.commit('setIndexOfOffer', this.selectedItem);
    }
  }
}
</script>

<style scoped>
@media (max-width: 992px) {
  .toggle_menu{
    width: 100% !important;
  }
}
.toggle_menu{
  width: 26.5%;
}
button::after{
  content: '';
  display: inline-block;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: right center;
  background-color: currentColor;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
</style>

<!--<li role="presentation" class="h5" v-for="(offers, id) in OFFERS.Jobs" :key="id">-->
<!--<a href="#ld-tab-pane-1" aria-expanded="false" aria-controls="ld-tab-pane-1"-->
<!--   role="tab" data-toggle="tab">{{offers.name}}</a>-->
<!--</li>-->