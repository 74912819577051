<template>

  <header>
    <div class="qw main-header-overlay">
      <div></div>
      <div style="align-items: center; display: flex;">
<!--        <div v-if="this.CHANGE_HEADER" class="ld-fh-txt h1 text-white mb-5">Engineers for Future</div>-->
        <img v-if="!this.CHANGE_HEADER" src="../../public/assets/img/logo_white.png" style="height: 90px; margin-top: 50px">
      </div>
      <div><a v-if="this.CHANGE_HEADER" href="#jobs" data-localscroll="true">Вакансии</a></div>

    </div>
  </header>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: "main-header",
  computed: mapGetters(['CHANGE_HEADER']),
}
</script>

<style scoped>

</style>