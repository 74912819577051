<template>
  <div id="jobs">
    <div v-for="(offer, id) in OFFERS.Jobs" :key="id">
      <section class="vc_row bg-light" v-show="id % 2 === 0">
        <div class="container-fluid px-0">
          <div class="mx-0 d-md-flex flex-wrap align-items-stretch margin_left">

            <div class="lqd-column col-lg-6 col-xs-12 py-9 d-md-flex flex-wrap align-items-center">

              <div class="w-100">
                <div class="col-md-8 col-md-offset-2">
                  <header class="fancy-title mb-5">
                    <h2>{{ offer.name }}</h2>
                  </header>

                  <p class="mb-5 font-size-17 lh-175">
                    {{ offer.description }}</p>
                  <div class="btn_align">
                  <v-btn @click="changeJobId(id)" class="btn btn-solid semi-round btn-bordered border-thin px-2">
										<span>
											<span class="btn-txt">Открыть Вакансию</span>
										</span>
                  </v-btn>
                    </div>

                </div><!-- /.col-md-10 col-md-offset-1 -->
              </div><!-- /.row -->

            </div><!-- /.lqd-column col-lg-6 -->
            <div v-bind:style="{ 'background-image': 'url(assets/jobs_img/' + offer.image_name + ')' }"
                 class="lqd-column col-lg-6 col-xs-12 visible-lg px-0 bg-cover">
              <figure>
                <img class="invisible" src="../../public/assets/demo/bg/bg-89.jpg" alt="Image">
              </figure>
            </div><!-- /.lqd-column col-lg-6 px-0 -->

          </div><!-- /.row mx-0 -->
        </div><!-- /.container-fluid px-0 -->
      </section>
      <section class="vc_row bg-dark" v-show="id % 2 === 1">
        <div class="container-fluid px-0">
          <div class="mx-0 d-md-flex flex-wrap align-items-stretch margin_left">

            <div v-bind:style="{ 'background-image': 'url(assets/jobs_img/' + offer.image_name + ')' }"
                 class="lqd-column col-lg-6 col-xs-12 visible-lg px-0 bg-cover">
              <figure>
                <img class="invisible" src="../../public/assets/demo/bg/bg-90.jpg" alt="Image">
              </figure>
            </div><!-- /.lqd-column col-lg-6 px-0 -->

            <div class="lqd-column col-lg-6 col-xs-12 py-9 px-md-9 d-md-flex flex-wrap align-items-center">

              <div class="w-100">
                <div class="col-md-10 col-md-offset-1">

                  <header class="fancy-title mb-5">
                    <h2 class="text-white">{{ offer.name }}</h2>
                  </header>

                  <p class="mb-5 font-size-17 lh-175 text-fade-white-05">{{ offer.description }}</p>
                    <div  class="btn_align">
                  <v-btn @click="changeJobId(id)"
                         class="btn btn-solid semi-round btn-bordered border-thin px-2">
										<span>
											<span class="btn-txt">Открыть Вакансию</span>
										</span>
                  </v-btn>
                    </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "main-jobs",
  computed: mapGetters(['OFFERS']),
  methods: {
    changeJobId(id) {
      this.$store.commit('setIndexOfOffer', id)
      this.$router.push({path: '/jobs'})
    }
  }
}
</script>

<style scoped>
.btn {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  padding: 0 !important;
  position: relative !important;
  border: 1px solid var(--color-primary) !important;
  font-size: 1em !important;
  white-space: nowrap !important;
  transition: all .3s ease !important;
  box-shadow: 0 0 0 rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%) !important;
  height: 65px !important;
  background-color: #4762ff !important;
  color: white;
}
</style>